<template>
    <ytFrame ytLink="https://www.youtube.com/embed/TTENrObjo1A?feature=share" />
</template>
<script>
import {mapState, mapMutations} from 'vuex';
import ytFrame from '@/components/ytFrame.vue'

export default{
    name: 'mVideo',
    components:{
        ytFrame
    },
    data(){
        return{

        }
    },
    methods:{
        ...mapMutations(['closeModal'])
    },
    computed: {
        ...mapState(['apiURL', 'showModal']),
    }

}
</script>
<style lang="scss" scoped>
</style>