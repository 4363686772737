<template>
    <h2>Resumen</h2>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit veniam aspernatur ea iusto explicabo, repellat obcaecati quisquam, amet accusamus voluptates in repellendus voluptatem eaque vel nostrum quas deserunt a ullam!</p>
</template>
<script>
import {mapState, mapMutations} from 'vuex';

export default{
    name: 'mResumen',
    data(){
        return{
            param: parseInt(this.$route.params.r),
            region: null,
            proyectosB: null,
            proyectosM: null,
            RMselect: '',
            pr: ''
        }
    },
    async created() {
        
        await this.loadRegion();
        await this.loadProyectos();

    },
    methods:{
        ...mapMutations(['closeModal']),
        async loadRegion() {
            this.region = null;
            let url = `/bdd/regiones.json`;
            try {
                let rs = await fetch(url);
                let data = await rs.json();
                data = data.salida;

                let r = data.filter((data) => {
                    return data.order == this.param;
                });

                this.region = r[0];
            } catch (e) {
                console.log(e);
            }
        },
        async loadProyectos(x) {
            let id;
            let r = this.region;
            if (x != undefined && x != '' && x != null) {
                id = x;
            } else {
                id = r.id;
            }

            this.RMselect = id

            let url = `${this.apiURL}/api/?clase=MasterData&metodo=getProyectFiltered&parametros={"filters":[{"field":"PAR", "value":${id}}]}`;
            //console.warn(url);

            try {
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);
                data = data.salida;

                this.proyectosB = data.filter((data) => {
                    return data.nivel == "Básica";
                });
                this.proyectosM = data.filter((data) => {
                    return data.nivel == "Media";
                });

            } catch (e) {
                console.log(e);
            }


        },


    },
    computed: {
        ...mapState(['apiURL', 'showModal']),
    }
}
</script>
<style lang="scss" scoped>
</style>