<template>
    <header>
        <h2>Reestablecer contraseña</h2>
    </header>
    <main>
        <p>Ingrese su correo asignado para el evento.</p>
        <form @submit.prevent="sendPass">
            <div class="boxItem">
                <label for="email"></label>
                <div class="formItem">
                    <input type="email" v-model="form.mail" name="email" placeholder="correo@correo.cl">
                </div>
            </div>
            <div id="msg">{{this.form.msg}}</div>
            <div class="boxSubmit">
                <button class="btn" :disabled="form.mail==''">enviar</button>
            </div>
        </form>
    </main>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
    name:'mPassword',
    props:['contentModal'],
    data(){
        return{
            form:{
                mail:'',
                enviar:false,
                msg:''
            }
        }
    },
    methods:{
        ...mapMutations(['closeModal']),
        async sendPass(){
            this.enviar = true;
            let url = `${this.apiURL}/api/?clase=MasterData&metodo=resetPassword&parametros=${this.form.mail}`;
            try{
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

                if(data.resultado){
                    this.form.msg = 'Una nueva contraseña fue enviada a su correo, utilícela para iniciar sesión.';
                    this.form.mail = '';

                    setTimeout(()=>{
                        this.form={
                            mail:'',
                            enviar:false,
                            msg:''
                        }
                        this.closeModal();
                    }, 10000)

                }else{
                    console.error(data.error);
                    this.form.msg = 'Oops.. Hubo un error, intente nuevamente.';
                }

            }catch(e){
                console.log(e);
            }
        }
    },
    computed:{
        ...mapState(['apiURL','showModal'])
    }
}
</script>

<style lang="scss" scoped>
article.modal{
    position: fixed;
    top:0;
    left:0;
    z-index:500;
    width: 100vw;
    height: 100vh;

    display:none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding:20px;

    background-color:rgba(0,0,0,0.7);
    &.show{
        display: flex;
    }

    .boxClose{
        button{
            background:transparent;
            color:#fff;
            padding:10px;
            transition: all .3s;
            font-size: 1.2rem;
            cursor: pointer;
            &:hover{
                transform: rotate(90deg);
            }
        }
    }
    .boxModal{
        background: #fff;
        padding:18px;
        border-radius: 17px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
        header{
            h2{text-align: center;}
        }
        main{
            .boxButtons{
                text-align: center;
                button{
                    margin: 5px;
                }
            }
        }
    }
    .boxSubmit{
        text-align: center;
    }
}
</style>