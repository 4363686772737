<template>
    <header>
        <h2>Encuesta de satisfacción</h2>
    </header>
    <main>
        <p>Regálanos unos segundos para responder esta encuesta de satisfacción.</p>
        <div class="linkEncuesta"><a href=""></a></div>
        <div class="boxButtons">
            <button class="btn" @click="closeModal()">Más Tarde</button>
            <button class="btn" @click="irEncuesta()">Ir a encuesta</button>
        </div>
    </main>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
    name:'mSatisfaccion',
    props:['contentModal'],
    data(){
        return{
        }
    },
    methods:{
        ...mapMutations(['closeModal']),
        async irEncuesta(){
            let programa=[];
            let url = `/bdd/programa.json`;
            try{
                let rs = await fetch(url);
                let data = await rs.json();

                programa = data;
            }catch(e){
                console.log(e);
            }
            

            const hoy = new Date();
            
            for(let enc of programa){
                let fha = enc.fecha_activa.split(' ');
                let fa = fha[0].split('-');
                let ha = fha[1].split(':');
                let f_activa = new Date(fa[2], fa[1]-1, fa[0], ha[0], ha[1]);

                let fht = enc.fecha_termino.split(' ');
                let ft = fht[0].split('-');
                let ht = fht[1].split(':');
                let f_termino = new Date(ft[2], ft[1]-1, ft[0], ht[0], ht[1]);

                if(hoy >= f_activa && hoy <= f_termino){
                    document.location.href = '/encuesta-satisfaccion/'+enc.codigo
                }else{
                    console.log('No es fecha de Evento');
                }
            }
            this.closeModal()
            
            
        }
    },
    computed:{
        ...mapState(['apiURL','showModal'])
    }
}
</script>

<style lang="scss" scoped>
article.modal{
    position: fixed;
    top:0;
    left:0;
    z-index:500;
    width: 100vw;
    height: 100vh;

    display:none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding:20px;

    background-color:rgba(0,0,0,0.7);
    &.show{
        display: flex;
    }

    .boxClose{
        button{
            background:transparent;
            color:#fff;
            padding:10px;
            transition: all .3s;
            font-size: 1.2rem;
            cursor: pointer;
            &:hover{
                transform: rotate(90deg);
            }
        }
    }
    .boxModal{
        background: #fff;
        padding:18px;
        border-radius: 17px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
        header{
            h2{text-align: center;}
        }
        main{
            .boxButtons{
                text-align: center;
                button{
                    margin: 5px;
                }
            }
        }
    }
    .boxSubmit{
        text-align: center;
    }
}
</style>