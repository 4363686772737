<template>
    <article id="modalSuscribe" class="modal" :class="showModal==true?'show':''">
        <div class="boxClose">
            <button @click="closeModal()"><i class="fas fa-times"></i></button>
        </div>
        <div class="boxModal">

            <mSuscripcion v-if="modal == 'suscripcion'"/>
            <mSatisfaccion v-if="modal == 'satisfaccion'"/>
            <mPassword v-if="modal == 'password'"/>
            <mVideo v-if="modal == 'video' "/>
            <mResumen v-if="modal == 'resumen'"/>

        </div>
    </article>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import mSuscripcion from '@/components/modal-Suscripcion.vue'
import mSatisfaccion from '@/components/modal-Satisfaccion.vue'
import mPassword from '@/components/modal-Password.vue'
import mVideo from '@/components/modal-Video.vue'
import mResumen from '@/components/modal-Resumen.vue'

export default {
    name:'modal',
    props:['modal'],
    data(){
        return{
            
        }
    },
    created(){
        console.log('modal:',this.modal);
    },
    components:{
        mSuscripcion, mSatisfaccion, mPassword, mVideo, mResumen
    },
    methods:{
        ...mapMutations(['closeModal']),
        
    },
    computed:{
        ...mapState(['apiURL','showModal'])
    }
}
</script>

<style lang="scss" scoped>
article.modal{
    position: fixed;
    top:0;
    left:0;
    z-index:800;
    width: 100vw;
    height: 100vh;

    display:none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding:20px;

    background-color:rgba(0,0,0,0.7);
    &.show{
        display: flex;
    }

    .boxClose{
        button{
            background:transparent;
            color:#fff;
            padding:10px;
            transition: all .3s;
            font-size: 1.2rem;
            cursor: pointer;
            &:hover{
                transform: rotate(90deg);
            }
        }
    }
    .boxModal{
        background: #fff;
        padding:18px;
        border-radius: 17px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
        
    }
}
</style>