<template>
    <header>
        <h2>Suscríbete</h2>
    </header>
    <main>
        <p>¡Sigamos en contacto!</p>
        <form @submit.prevent="saveSuscribe">
            <div class="boxItem">
                <label for="email"></label>
                <div class="formItem">
                    <input type="email" v-model="form.mail" name="email" placeholder="correo@correo.cl">
                </div>
            </div>
            <div class="politicas">
                <input type="checkbox" name="politicas" v-model="form.politicas"> <span>Estoy de acuerdo con las <a href="assets/pdf/politicas.pdf">políticas de privacidad</a> establecidas.</span>
            </div>
            <div id="msg">{{this.form.msg}}</div>
            <div class="boxSubmit">
                <button class="btn" :disabled="!form.enviar || !form.politicas || form.mail==''">enviar</button>
            </div>
        </form>
    </main>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
export default {
    name:'mSuscripcion',
    data(){
        return{
            form:{
                mail:'',
                enviar:true,
                msg:'',
                politicas:false
            }
        }
    },
    methods:{
        async saveSuscribe(){
            this.enviar = false;
            let url = `${this.apiURL}/api/?clase=MasterData&metodo=setSubscription&parametros={"email":"${this.form.mail}","data":""}`;
            console.warn('url=>', url);
            try{
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

                if(data.salida){
                    this.form.msg = 'Su correo ha sido agregado a nuestra lista!';

                    setTimeout(()=>{
                        this.form={
                            mail:'',
                            enviar:false,
                            msg:''
                        }
                    }, 3000)
                    


                }else{
                    console.error(data.error);
                }

            }catch(e){
                console.log(e);
            }
        }
    },
    computed:{
        ...mapState(['apiURL'])
    }
}
</script>

<style lang="scss" scoped>
header{
    h2{text-align: center;}
}
main{
    p{
        text-align: center;
    }
}
.boxSubmit{
    text-align: center;
}
</style>