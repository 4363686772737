<template>
    <iframe width="100%" height="100%" :src="ytLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>
export default {
    name: 'Auditorio',
    props:['ytLink'],
    data(){
        return{
            ytFrameD: ''
        }
    },
    methods:{
        ytFrameF(){
            let ytFrame = document.createElement('iframe');
            ytFrame.src = 'https://www.youtube.com/watch?v=gIq4iH7bywA';
            ytFrame.classList.add('ytVideo');
            ytFrame.allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
            ytFrame.allowfullscreen = true;

            //this.ytFrameD = ytFrame;

            console.log(ytFrame);
        }
    }
}
</script>

<style>

</style>